import {Component, OnInit} from '@angular/core';
import {LayoutService} from './service/app.layout.service';
import {AuthService} from '../modulos/core/services/auth.service';
import {AppMenu} from '../_helpers/app-menu';
import {ErroHttp} from '../_helpers/erro-http';
import {Router} from '@angular/router';
import {LocalStorageService} from '../modulos/core/services/localstorage-service';
import {Rotas} from '../consts/rotas';

@Component({
    selector: 'app-menu',
    templateUrl: './app.menu.component.html',
    providers: [
        AuthService
    ]
})
export class AppMenuComponent implements OnInit {

    model: any;
    menus: AppMenu[] = [];
    carregando: boolean;

    constructor(public layoutService: LayoutService, private _authService: AuthService, private _router: Router,
                private localStorageService: LocalStorageService) {
        this.model = [];
        this.menus = [];
        this.carregando = false;
    }

    ngOnInit() {
        this.model = [
            {
                label: 'Home',
                items: [
                    {label: 'Dashboard', icon: 'pi pi-fw pi-home', routerLink: ['/']},
                ]
            },
        ];

        this.carregando = true;

        this.menus = [];
        this.model.forEach(m => this.menus.push(m));
        this._authService.getMenusUsuario({})
            .subscribe({
                next: (res: any) => {

                    if (res && res.permissoes) {
                        const listaModulosOrdenada = res.permissoes.sort((obj1: any, obj2: any) => {
                            if (obj1.modulo.ordem > obj2.modulo.ordem) {
                                return 1;
                            }

                            if (obj1.modulo.ordem < obj2.modulo.ordem) {
                                return -1;
                            }

                            return 0;
                        });

                        listaModulosOrdenada.forEach((p: any) => {
                            const modulo = p.modulo;

                            const listaMenus: AppMenu[] = [];
                            const listaMenusOrdenada = modulo.menus.sort((obj1: any, obj2: any) => {
                                if (obj1.ordem > obj2.ordem) {
                                    return 1;
                                }

                                if (obj1.ordem < obj2.ordem) {
                                    return -1;
                                }

                                return 0;
                            });
                            listaMenusOrdenada.forEach((m: any) => {
                                if (m.acoes.menu) {
                                    const opcoesMenu = {
                                        label: m.nome,
                                        title: (m.descricao ? m.descricao : ''),
                                        icon: m.icone,
                                        routerLink: m.path,
                                        routerLinkActiveOptions: null,
                                        badge: null,
                                        target: null,
                                        url: null,
                                        items: null,
                                        canView: m.acoes.ver,
                                        canCreate: m.acoes.criar,
                                        canEdit: m.acoes.editar,
                                        canDelete: m.acoes.excluir,
                                        canExportPdf: m.acoes.pdf,
                                        canExportCsv: m.acoes.csv,
                                    };
                                    listaMenus.push(AppMenu.fromAppMenu(opcoesMenu));
                                }
                            });

                            const opcoes = {
                                label: modulo.nome,
                                title: (modulo.descricao ? modulo.descricao : ''),
                                icon: modulo.icone,
                                routerLink: null,
                                routerLinkActiveOptions: null,
                                badge: null,
                                target: null,
                                url: '#',
                                items: listaMenus
                            };
                            const appMenu = AppMenu.fromAppMenu(opcoes);

                            this.menus.push(appMenu);
                        });
                    }

                    this.localStorageService.store('meus_menus', JSON.parse(JSON.stringify(this.menus)));
                    this.carregando = false;
                },
                error: (err: ErroHttp) => {
                    console.error(err);
                    this.carregando = false;
                    if (err.httpCode == 401 || err.httpCode >= 500) {
                        this._authService.logout()
                            .subscribe({
                                next: (res: any) => {
                                    this._router.navigate([Rotas.AUTH_LOGIN]);
                                },
                                error: (err: any) => {
                                    this._router.navigate([Rotas.AUTH_LOGIN]);
                                }
                            });
                    }
                }
            });
    }
}
