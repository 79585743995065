<ul class="layout-menu">
    <ng-container *ngFor="let item of menus; let i = index;">
        <li *ngIf="!item.separator" [index]="i" [item]="item" [root]="item.items && item.items.length > 0" app-menuitem></li>
        <li *ngIf="item.separator" class="menu-separator"></li>
    </ng-container>

    <li *ngIf="carregando">
        <app-loading></app-loading>
    </li>
</ul>
