<div class="layout-topbar">
    <a class="layout-topbar-logo" routerLink="">
        <img alt="logo" src="assets/layout/images/{{ layoutService.config.colorScheme === 'light' ? environment.logo_dark : environment.logo_white }}">
        <span>{{ environment.nomeSistema }}</span>
    </a>

    <button #menubutton (click)="layoutService.onMenuToggle()" class="p-link layout-menu-button layout-topbar-button">
        <i class="pi pi-bars"></i>
    </button>

    <button #topbarmenubutton (click)="layoutService.showProfileSidebar()" class="p-link layout-topbar-menu-button layout-topbar-button">
        <i class="pi pi-ellipsis-v"></i>
    </button>

    <div #topbarmenu *ngIf="usuario" [ngClass]="{'layout-topbar-menu-mobile-active': layoutService.state.profileSidebarVisible}" class="layout-topbar-menu">
        <button [routerLink]="rotas.NOTIFICACOES" [title]="'HEADER.MENUS.NOTIFICACOES_TITULO' | translate" class="p-link layout-topbar-button">
            <i *ngIf="qdeNotificacoes > 0" [value]="qdeNotificacoes.toString()" class="pi pi-bell" pBadge severity="info"></i>
            <i *ngIf="qdeNotificacoes <= 0" class="pi pi-bell"></i>
            <span>{{ 'HEADER.MENUS.NOTIFICACOES_TITULO' | translate }}</span>
        </button>
        <button (click)="temMensagemChat=0" *ngIf="environment.habilitarChat" [ngClass]="{'tem-novas-msg': temMensagemChat > 0}" [routerLink]="rotas.MODULO_CHAT"
                [title]="'HEADER.MENUS.CHAT_TITULO' | translate" class="p-link layout-topbar-button">
            <i class="pi pi-comments"></i>
            <span>{{ 'HEADER.MENUS.CHAT_TITULO' | translate }}</span>
        </button>
        <button [routerLink]="rotas.AGENDA" [title]="'HEADER.MENUS.CALENDARIO_TITULO' | translate" class="p-link layout-topbar-button">
            <i *ngIf="qdeEventosHoje > 0" [value]="qdeEventosHoje.toString()" class="pi pi-calendar" pBadge severity="info"></i>
            <i *ngIf="qdeEventosHoje <= 0" class="pi pi-calendar"></i>
            <span>{{ 'HEADER.MENUS.CALENDARIO_TITULO' | translate }}</span>
        </button>
        <button [routerLink]="rotas.PERFIL_USUARIO" [title]="'HEADER.MENUS.PERFIL_TITULO' | translate" class="p-link layout-topbar-button">
            <p-avatar *ngIf="!usuario.imagem" [title]="usuario.nome" icon="pi pi-user" shape="circle" size="large"></p-avatar>
            <p-avatar *ngIf="usuario.imagem" [image]="usuario.imagem" [title]="usuario.nome" shape="circle" size="large"></p-avatar>
            <span class="ml-2">{{ 'HEADER.MENUS.PERFIL_TITULO' | translate }}</span>
        </button>

        <button (click)="showModalTrocarFilial()" *ngIf="listaFiliais.length > 0" [title]="'HEADER.MENUS.EMPRESAS_TITULO' | translate" class="p-link layout-topbar-button"
                type="button">
            <i class="pi pi-building"></i>
            <span class="ml-2">{{ 'HEADER.MENUS.EMPRESAS_TITULO' | translate }}</span>
        </button>

    </div>
    <button (click)="efetuarLogoff()" class="p-link layout-menu-button layout-topbar-button">
        <i class="pi pi-sign-out"></i>
    </button>
</div>

<p-confirmDialog [acceptLabel]="'CORE.SIM' | translate" [header]="'AUTH.CONFIRMACAO_LOGOFF_TITULO' | translate" [message]="'AUTH.CONFIRMACAO_LOGOFF_SUB_TITULO' | translate"
                 [rejectLabel]="'CORE.NAO' | translate" [style]="{width: '350px'}"
                 acceptButtonStyleClass="p-button-raised p-button-success" icon="pi pi-exclamation-triangle"
                 key="confirmacao_logoff"
                 rejectButtonStyleClass="p-button-raised p-button-danger"
></p-confirmDialog>

<p-dialog [(visible)]="mostrarModalTrocarFilial" [draggable]="false" [modal]="true" [resizable]="false" [style]="{width: '50vw'}" header="Trocar filial">
    <p-listbox [group]="true" [options]="listaFiliais" optionLabel="label">
        <!-- Template para o grupo (corresponde a empresa) -->
        <ng-template let-group pTemplate="group">
            <div class="flex align-items-center">
                <span>{{group.label}}</span>
            </div>
        </ng-template>

        <!-- Template para o item (corresponde a filial) -->
        <ng-template let-item pTemplate="item">
            <div (click)="trocarFilial($event, item)" class="flex align-items-center">
                <span style="padding-left: 15px">{{item.label}}</span>
            </div>
        </ng-template>
    </p-listbox>
</p-dialog>
