import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {RouterModule} from '@angular/router';
import {TranslateModule} from '@ngx-translate/core';
import {BackTopComponent} from './back-top.component';
import {ButtonModule} from 'primeng/button';
import {RippleModule} from 'primeng/ripple';

@NgModule({
    declarations: [
        BackTopComponent,
    ],
    imports: [
        CommonModule,
        RouterModule,
        TranslateModule,
        ButtonModule,
        RippleModule,
    ],
    exports: [
        BackTopComponent,
    ]
})
export class BackTopModule {
}
