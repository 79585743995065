import {NgModule} from '@angular/core';
import {BrowserModule} from '@angular/platform-browser';
import {FormsModule} from '@angular/forms';
import { provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {InputTextModule} from 'primeng/inputtext';
import {SidebarModule} from 'primeng/sidebar';
import {BadgeModule} from 'primeng/badge';
import {RadioButtonModule} from 'primeng/radiobutton';
import {InputSwitchModule} from 'primeng/inputswitch';
import {RippleModule} from 'primeng/ripple';
import {AppMenuComponent} from './app.menu.component';
import {AppMenuitemComponent} from './app.menuitem.component';
import {RouterModule} from '@angular/router';
import {AppTopBarComponent} from './app.topbar.component';
import {AppFooterComponent} from './app.footer.component';
import {AppConfigModule} from './config/config.module';
import {AppSidebarComponent} from './app.sidebar.component';
import {AppLayoutComponent} from './app.layout.component';
import {ConfirmDialogModule} from 'primeng/confirmdialog';
import {TranslateModule} from '@ngx-translate/core';
import {LoadingModule} from '../componentes/loading/loading.module';
import {BackTopModule} from '../componentes/back-top/back-top.module';
import {LoadingBackdropModule} from '../componentes/loading-backdrop/loading-backdrop.module';
import {ImageModule} from 'primeng/image';
import {PipesModule} from '../pipes/pipes.module';
import {AvatarModule} from 'primeng/avatar';
import {TieredMenuModule} from 'primeng/tieredmenu';
import {DialogModule} from 'primeng/dialog';
import {ListboxModule} from 'primeng/listbox';

@NgModule({ declarations: [
        AppMenuitemComponent,
        AppTopBarComponent,
        AppFooterComponent,
        AppMenuComponent,
        AppSidebarComponent,
        AppLayoutComponent,
    ],
    exports: [AppLayoutComponent], imports: [BrowserModule,
        FormsModule,
        BrowserAnimationsModule,
        InputTextModule,
        SidebarModule,
        BadgeModule,
        RadioButtonModule,
        InputSwitchModule,
        RippleModule,
        RouterModule,
        AppConfigModule,
        ConfirmDialogModule,
        TranslateModule,
        LoadingModule,
        BackTopModule,
        LoadingBackdropModule,
        ImageModule,
        PipesModule,
        AvatarModule,
        TieredMenuModule,
        DialogModule,
        ListboxModule], providers: [provideHttpClient(withInterceptorsFromDi())] })
export class AppLayoutModule {
}
