import {Component, ElementRef, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {ConfirmationService, MenuItem} from 'primeng/api';
import {LayoutService} from './service/app.layout.service';
import {AuthService} from '../modulos/core/services/auth.service';
import {ActivatedRoute, Router} from '@angular/router';
import {Rotas, semBarra} from '../consts/rotas';
import {UsuarioModel} from '../modulos/admin/models/usuario.model';
import {LocalStorageService} from '../modulos/core/services/localstorage-service';
import {environment} from '../../environments/environment';
import {AgendaService} from '../modulos/core/services/agenda.service';
import {NotificacaoService} from '../modulos/core/services/notificacao.service';
import {AppTopbarService} from './app.topbar.service';
import {Subscription} from 'rxjs';
import {ErroHttp} from '../_helpers/erro-http';
import {FilialModel} from '../modulos/admin/models/filial.model';
import {WebSocketService} from '../modulos/chat/services/web-socket.service';
import {ChatModel} from '../modulos/chat/models/chat.model';
import {MensagemService} from '../modulos/chat/services/mensagem.service';

@Component({
    selector: 'app-topbar',
    templateUrl: './app.topbar.component.html',
    styleUrls: ['./app.topbar.component.scss'],
    providers: [
        ConfirmationService
    ]
})
export class AppTopBarComponent implements OnInit, OnDestroy {

    qdeEventosHoje: number;
    qdeNotificacoes: number;
    listaFiliais: MenuItem[];
    mostrarModalTrocarFilial: boolean;
    temMensagemChat: number;

    @ViewChild('menubutton') menuButton!: ElementRef;

    @ViewChild('topbarmenubutton') topbarMenuButton!: ElementRef;

    @ViewChild('topbarmenu') menu!: ElementRef;
    readonly rotas: typeof Rotas = Rotas;
    readonly environment: typeof environment = environment;
    usuario: UsuarioModel;

    notificacaoSubscription: Subscription;
    agendaSubscription: Subscription;
    socketSubscription!: Subscription;
    socketOkSubscription!: Subscription;
    leuTodasMensagensChatSubscription!: Subscription;

    constructor(public layoutService: LayoutService, private authService: AuthService, private _router: Router, private confirmationService: ConfirmationService,
                private localStorageService: LocalStorageService, private agendaService: AgendaService, private notificacaoService: NotificacaoService,
                private appTopBarService: AppTopbarService, private webSocketService: WebSocketService, private activatedRoute: ActivatedRoute,
                private mensagemService: MensagemService) {
        this.usuario = this.localStorageService.getData('currentUser');
        this.qdeEventosHoje = 0;
        this.qdeNotificacoes = 0;
        this.listaFiliais = [];
        this.mostrarModalTrocarFilial = false;
        this.temMensagemChat = 0;

        this.notificacaoSubscription = this.appTopBarService.notificacaoSource$.subscribe(value => {
            this.buscarQdeNotificacoesNaoLidas();
        });
        this.agendaSubscription = this.appTopBarService.agendaSource$.subscribe(value => {
            this.buscarQdeEventosHoje();
        });
    }

    ngOnInit() {
        // aqui fica observando alterações do usuário, e se ocorrer, altera a imagem
        this.authService.getAlteracaoUsuario().subscribe(res => {
            this.usuario.imagem = this.authService.getAuthenticatedUser().imagem;
        });

        this.qdeEventosHoje = 0;
        this.buscarQdeEventosHoje();

        this.qdeNotificacoes = 0;
        this.buscarQdeNotificacoesNaoLidas();

        this.buscarFiliaisUsuario();

        if (environment.habilitarChat) {

            this.socketOkSubscription = this.webSocketService.socketOk$
                .subscribe(v => {
                    this.socketSubscription = this.webSocketService.getSocket()
                        .subscribe((data: any) => {
                            const chat = ChatModel.jsonToObject(data);

                            const activatedRoute = this.activatedRoute.snapshot.url;
                            const naRotaDoChat = activatedRoute[0] && activatedRoute[0].path == semBarra(Rotas.MODULO_CHAT);
                            if (!naRotaDoChat && chat.usuarioDestino && chat.usuarioDestino.id == this.authService.getAuthenticatedUser().id) {
                                this.temMensagemChat = 1;
                            }
                        });
                });

            this.leuTodasMensagensChatSubscription = this.webSocketService.leuTodasMensagensChat$
                .subscribe((res: any) => {
                    this.temMensagemChat = 0;
                });

            this.webSocketService.connect();

            this.verificarMensagensNaoLidasChat();
        }
    }

    verificarMensagensNaoLidasChat() {
        this.mensagemService.getQdeNaoLidas()
            .subscribe({
                next: (res: any) => {
                    this.temMensagemChat = res.dados;
                }
            });
    }

    buscarQdeNotificacoesNaoLidas() {
        this.notificacaoService.getQdeNaoLidas().subscribe({
            next: (res: any) => {
                this.qdeNotificacoes = res;
            }
        });
    }

    buscarQdeEventosHoje() {
        this.agendaService.getListaHoje().subscribe({
            next: (res: any) => {
                this.qdeEventosHoje = res.qde_registros;
            }
        });
    }

    efetuarLogoff() {
        this.confirmationService.confirm({
            key: 'confirmacao_logoff',
            accept: () => {
                this.authService.logout()
                    .subscribe({
                        next: (res: any) => {
                            window.location.reload();
                        },
                        error: (err: ErroHttp) => {
                            window.location.reload();
                        }
                    });
            }
        });
    }

    ngOnDestroy() {
        if (this.notificacaoSubscription) {
            this.notificacaoSubscription.unsubscribe();
        }
        if (this.agendaSubscription) {
            this.agendaSubscription.unsubscribe();
        }
        if (this.socketSubscription) {
            this.socketSubscription.unsubscribe();
        }
        if (this.socketOkSubscription) {
            this.socketOkSubscription.unsubscribe();
        }
        if (this.leuTodasMensagensChatSubscription) {
            this.leuTodasMensagensChatSubscription.unsubscribe();
        }
    }

    buscarFiliaisUsuario() {
        this.listaFiliais = [];
        let lista: any[] = [];
        this.authService.minhasFiliais()
            .subscribe({
                next: (res: any) => {
                    if (res.dados) {
                        res.dados.forEach((f: any) => {
                            let filial = FilialModel.jsonToObject(f);
                            let item = lista.find(i => i.id == filial.empresa.id);
                            if (!item) {
                                item = {
                                    label: filial.empresa.nomeFantasia,
                                    id: filial.empresa.id.toString(),
                                    items: [],
                                } as MenuItem;
                                lista.push(item);
                            }
                            item.items.push({
                                label: filial.nomeFantasia,
                                id: filial.id.toString(),
                            } as MenuItem);
                        });
                        this.listaFiliais = lista;
                    }
                }
            });
    }

    trocarFilial($event: MouseEvent, item: MenuItem) {
        $event.preventDefault();
        this.authService.trocarFilial(Number.parseInt(<string>item.id))
            .subscribe({
                next: (user: any) => {
                    this.authService.setarUsuarioPosLogin(user);
                    this.mostrarModalTrocarFilial = false;
                    window.location.reload();
                }
            });
    }

    showModalTrocarFilial() {
        this.mostrarModalTrocarFilial = true;
    }
}
