import {Component, OnDestroy, OnInit} from '@angular/core';
import {PrimeNGConfig} from 'primeng/api';
import {SisBaseTranslateService} from './modulos/core/services/sisbase-translate-service';
import {environment} from '../environments/environment';
import {Subscription} from 'rxjs';
import {AuthService} from './modulos/core/services/auth.service';
import {Router} from '@angular/router';
import {LayoutService} from './layout/service/app.layout.service';
import {WebSocketService} from './modulos/chat/services/web-socket.service';

declare var $: any;

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html'
})
export class AppComponent implements OnInit, OnDestroy {

    translateSubscription: Subscription;

    constructor(private primengConfig: PrimeNGConfig, private _translateService: SisBaseTranslateService, private _authService: AuthService,
                private _router: Router, public layoutService: LayoutService, private webSocketService: WebSocketService) {
        // idioma de fallback
        this._translateService.setDefaultLang(environment.idiomaPadrao);

        // idioma para usar
        this._translateService.use(this._translateService.currentIdioma());

        this.translateSubscription = this._translateService.get('primeng').subscribe(res => this.primengConfig.setTranslation(res));
    }

    ngOnInit() {
        this.primengConfig.ripple = true;
        if (environment.habilitarChat) {
            this.habilitarSocket();
        }
    }

    ngOnDestroy() {
        if (environment.habilitarChat) {
            this.webSocketService.close();
        }
    }

    habilitarSocket() {
        this.webSocketService.connect();
    }
}
