<div [ngClass]="containerClass" class="layout-wrapper">
    <app-topbar></app-topbar>
    <div class="layout-sidebar">
        <app-sidebar></app-sidebar>
    </div>
    <div class="layout-main-container">
        <div class="layout-main">
            <router-outlet></router-outlet>
        </div>
        <app-footer></app-footer>
    </div>
    <app-config *ngIf="!environment.production"></app-config>
    <div class="layout-mask"></div>
</div>

<app-back-top></app-back-top>
<app-loading-backdrop></app-loading-backdrop>
