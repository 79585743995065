import {Component} from '@angular/core';
import {LayoutService} from './service/app.layout.service';
import {environment} from '../../environments/environment';
import {AuthService} from '../modulos/core/services/auth.service';
import {UsuarioModel} from '../modulos/admin/models/usuario.model';

@Component({
    selector: 'app-footer',
    templateUrl: './app.footer.component.html'
})
export class AppFooterComponent {

    readonly environment: typeof environment = environment;
    currentUser: UsuarioModel;

    constructor(public layoutService: LayoutService, private authService: AuthService) {
        this.currentUser = this.authService.getAuthenticatedUser();
    }
}
