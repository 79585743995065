import {CUSTOM_ELEMENTS_SCHEMA, LOCALE_ID, NgModule} from '@angular/core';
import {CommonModule, LocationStrategy, PathLocationStrategy} from '@angular/common';
import {AppComponent} from './app.component';
import {AppRoutingModule} from './app-routing.module';
import {AppLayoutModule} from './layout/app.layout.module';
import {ProductService} from './demo/service/product.service';
import {CountryService} from './demo/service/country.service';
import {CustomerService} from './demo/service/customer.service';
import {EventService} from './demo/service/event.service';
import {IconService} from './demo/service/icon.service';
import {NodeService} from './demo/service/node.service';
import {PhotoService} from './demo/service/photo.service';
import {TranslateLoader, TranslateModule} from '@ngx-translate/core';
import {TranslateHttpLoader} from '@ngx-translate/http-loader';
import { HTTP_INTERCEPTORS, HttpClient, provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import {SisBaseHttpInterceptor} from './_helpers/sisbase-http.interceptor';
import {SisBaseTranslateService} from './modulos/core/services/sisbase-translate-service';
import {environment} from '../environments/environment';
import {JwtModule} from '@auth0/angular-jwt';

// AoT requires an exported function for factories
export function HttpLoaderFactory(http: HttpClient) {
    return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}

/**
 * Aqui deve ser definidos os temas que a aplicação terá, bem como o tema padrão
 * O nome dos temas são definidos no arquivo src/assets/scss/themes/themes.scss
 */

/*const themeServiceConfig: ThemeServiceConfig = {
    themes: ['default', 'demo'],
    defaultTheme: environment.temaDefaultBase,
    transitionConfig: {
        className: 'theme-transition',
        duration: 1500
    }
};*/

export function tokenGetterFn() {
    const user = JSON.parse(localStorage.getItem(environment.nomeUsuarioLocalStorage) as string);
    if (user && user.value) {
        return user.value.token;
    }
    return null;
}

export function getAuthSchemeJWT(req: any) {
    return 'Bearer ';
}

@NgModule({ declarations: [
        AppComponent,
    ],
    bootstrap: [AppComponent],
    schemas: [CUSTOM_ELEMENTS_SCHEMA], imports: [AppRoutingModule,
        AppLayoutModule,
        CommonModule,
        TranslateModule.forRoot({
            loader: {
                provide: TranslateLoader,
                useFactory: HttpLoaderFactory,
                deps: [HttpClient]
            }
        }),
        JwtModule.forRoot({
            config: {
                tokenGetter: tokenGetterFn,
                authScheme: getAuthSchemeJWT,
            },
        })], providers: [
        { provide: HTTP_INTERCEPTORS, useClass: SisBaseHttpInterceptor, multi: true },
        { provide: LocationStrategy, useClass: PathLocationStrategy },
        {
            provide: LOCALE_ID,
            deps: [SisBaseTranslateService],
            useFactory: (sisBaseTranslateService: SisBaseTranslateService) => sisBaseTranslateService.currentLocale()
        },
        /*{provide: THEME_CONFIG, useValue: themeServiceConfig},*/
        CountryService,
        CustomerService,
        EventService,
        IconService,
        NodeService,
        PhotoService,
        ProductService,
        provideHttpClient(withInterceptorsFromDi()),
    ] })
export class AppModule {
}
