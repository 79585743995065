import {RouterModule, Routes} from '@angular/router';
import {NgModule} from '@angular/core';
import {AppLayoutComponent} from './layout/app.layout.component';
import {Rotas, semBarra} from './consts/rotas';
import {environment} from '../environments/environment';


export function getListaRotas(): Routes {
    const routes: Routes = [
        {
            path: '',
            component: AppLayoutComponent,
            children: [
                // Usa o AppLayoutComponent como componente pai
                {path: '', loadChildren: () => import('./modulos/core/core.module').then(m => m.CoreModule)},
                {path: Rotas.MODULO_GERAL, loadChildren: () => import('./modulos/geral/geral.module').then(m => m.GeralModule)},
                {path: Rotas.MODULO_GUARDA, loadChildren: () => import('./modulos/guarda/guarda.module').then(m => m.GuardaModule)},
                {path: Rotas.MODULO_ADMIN, loadChildren: () => import('./modulos/admin/admin.module').then(m => m.AdminModule)},
                {path: Rotas.MODULO_AVP, loadChildren: () => import('./modulos/avp/avp.module').then(m => m.AvpModule)},
                {path: Rotas.MODULO_GED, loadChildren: () => import('./modulos/ged/ged.module').then(m => m.GedModule)},
            ]
        },
        {path: Rotas.MODULO_AUTH, loadChildren: () => import('./auth/auth.module').then(m => m.AuthModule)},
        {path: semBarra(Rotas.ERROR), loadChildren: () => import('./modulos/core/error/error.module').then(m => m.ErrorModule)},
        {path: semBarra(Rotas.FORBIDDEN), loadChildren: () => import('./modulos/core/access/access.module').then(m => m.AccessModule)},
        {path: semBarra(Rotas.NOT_FOUND), loadChildren: () => import('./modulos/core/notfound/notfound.module').then(m => m.NotfoundModule)},
    ];

    if (environment.habilitarChat) {
        const idx = routes.findIndex(r => r.path == '');
        if (idx != -1) {
            routes[idx]!.children!.push({
                path: semBarra(Rotas.MODULO_CHAT),
                loadChildren: () => import('./modulos/chat/chat.module').then(m => m.ChatModule)
            });
        }
    }

    // Deixar o 404 no final
    routes.push({path: '**', redirectTo: Rotas.NOT_FOUND});

    return routes;
}

@NgModule({
    imports: [
        RouterModule.forRoot(getListaRotas(),
            {
                scrollPositionRestoration: 'enabled',
                anchorScrolling: 'enabled',
                onSameUrlNavigation: 'reload',
                enableTracing: false,
                // preloadingStrategy: PreloadAllModules
            })
    ],
    exports: [RouterModule]
})
export class AppRoutingModule {
}
